<template>
  <div class="changeProfile">
    <div class="profileImage">
      <!-- img 100% width -->
      <img src="../../../assets/peoplePlaceholder.jpg" alt="Team image"/>
      <!-- Button -->
      <a href="#" class="editProfile">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19"
             viewBox="0 0 18 19">
          <defs>
            <clipPath id="clip-Edit">
              <rect width="18" height="19"/>
            </clipPath>
          </defs>
          <g id="Edit" clip-path="url(#clip-Edit)">
            <g id="edit-black-18dp" transform="translate(0.2 0.4)">
              <path id="Path_1228" data-name="Path 1228" d="M0,0H19.2V19.2H0Z" fill="none"/>
              <path id="Path_1229" data-name="Path 1229"
                    d="M3,14.4v3H6l8.848-8.848-3-3ZM17.168,6.232a.8.8,0,0,0,0-1.128L15.3,3.232a.8.8,0,0,0-1.128,0L12.7,4.7l3,3,1.464-1.464Z"
                    transform="translate(-0.6 -0.6)" fill="#707070"/>
            </g>
          </g>
        </svg>
        Choose photo
      </a>
      <a @click="$router.go(-1)" class="closeLink" type="button">
        <svg focusable="false" data-prefix="fas" data-icon="times"
             class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 352 512">
          <path fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
        </svg>
      </a>
    </div>
    <div class="container pt-5 mb-5 pb-5">
      <form @submit.prevent="updateProfile">
        <TextInput
            label="Username"
            :required="true"
            v-model:value="user.name"
        />
          <TextInput
              label="Team name"
              :required="true"
              v-model:value="user.team_name"
          />
        <TextInput
            label="Slogan"
            :required="false"
            v-model:value="user.slogan"
        />
        <TextInput
            label="Phone"
            :required="true"
            v-model:value="user.phone"
        />
        <TextInput
            label="Car registration"
            :required="false"
            v-model:value="user.car_reg"
        />
        <TextInput
            label="Car type"
            :required="false"
            v-model:value="user.car"
        />
        <TextInput
            label="Car color"
            :required="false"
            v-model:value="user.car_color"
        />

        <div class="form-group">
          <label class="form-check-label" for="teamMem">Team members</label>
          <div v-if="newMember === true">
            <div class="listItem text-dark mb-3" v-for="(member, index) in members" :key="index">
              <span>{{ member }}</span>
              <a href="#" class="pl-3" @click="remove(index)">
                <svg xmlns="http://www.w3.org/2000/svg" width="15"
                     height="19" viewBox="0 0 15 19">
                  <defs>
                    <clipPath id="clip-Delete">
                      <rect width="15" height="19"/>
                    </clipPath>
                  </defs>
                  <g id="Delete" clip-path="url(#clip-Delete)">
                    <path id="Icon_material-delete" data-name="Icon material-delete"
                          d="M8.439,21.406c-.076-.076,11.242-.106,11.271,0V8.257H8.439ZM20.649,5.439H17.362L16.423,4.5h-4.7l-.939.939H7.5V7.318H20.649Z"
                          transform="translate(-6.575 -3.5)" fill="#707070"/>
                  </g>
                </svg>

              </a>
            </div>
          </div>
          <input type="text" class="form-control" id="teamMem" name="member" v-model="newItem" placeholder="Alan Ford"
                 :class="{temMemDisable: disableAdd}">
        </div>

        <div class="addTeamMember pt-3">
          <a href="#" class="signinLink" :class="{disable: disableAdd}" @click="addMember(true)">&#43; ADD TEAM
            MEMBER</a>
        </div>
        <div class="form-group pt-1">
          <label class="form-check-label" for="teamDescription">Team description</label>
          <textarea class="form-control" id="teamDescription" name="description"></textarea>
        </div>
        <div class="form-group pt-3 pb-2">
          <div class="range-wrap">
            <label class="form-check-label pb-3" for="changeNotifications">Number of notifications</label>
            <div class="d-flex">
              <input type="range" class="form-range w-100" min="0" max="20" v-model="user.message_level"
                     id="changeNotifications">
              <output class="bubble" id="bubbleNotification"></output>
            </div>
          </div>
        </div>
        <div class="form-group text-center pt-5 pb-5">
          <button class="btn btn-secondary w-100 text-uppercase" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {useToast} from "vue-toastification";
import TextInput from "@/components/ui/TextInput";
import {UPDATE_USER} from "@/config/variables";

export default {
  setup() {
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      members: [],
      newItem: "",
      state: true,
      newMember: false,
      toggleClass: false,
      disableAdd: false,
    }
  },
  methods: {
    async updateProfile() {
      try {
        await axios.put(UPDATE_USER, this.user);
        this.$store.dispatch('loadUser', this.user);
        this.toast.success('Changes saved');
        this.$router.push({name: 'settings.profile'});
      } catch (e) {
        console.log(e)
        this.toast.error('error');
      }
    },
    makeBubbleNotification() {
      const val = this.user.message_level;
      const min = 0;
      const max = 20;
      const newVal = Number(((val - min) * 100) / (max - min));
      const bubble = document.querySelector("#bubbleNotification");
      bubble.innerHTML = this.user.message_level;

      // Sorta magic numbers based on size of the native UI thumb
      bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    },
    addMember(newState) {
      if (this.newItem !== "") {
        this.members.push(this.newItem);
        this.newItem = "";
        this.newMember = newState;
        if (this.members.length === 5) {
          this.disableAdd = true;
        } else {
          this.disableAdd = false;
        }
      }

      if (this.members.length >= 3) {
        this.toggleClass = true;
      } else {
        this.toggleClass = false;
      }

    },
    remove(index) {
      this.members.splice(index, 1);

      if (this.members.length === 5) {
        this.disableAdd = true;
      } else {
        this.disableAdd = false;
      }

      if (this.members.length >= 3) {
        this.toggleClass = true;
      } else {
        this.toggleClass = false;
      }
    },
  },
  watch: {
      'user.message_level'() {
      this.makeBubbleNotification();
    }
  },
  mounted() {
    this.makeBubbleNotification();
  },
  components: {
    TextInput,
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.changeProfile {
  overflow-y: scroll;
  overflow-x: hidden;

  .profileImage {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 90%;
    }

    @media screen and (min-width: 1000px) {
      width: 93%;
    }

    @media screen and (min-width: 1300px) {
      width: 95%;
    }

    @media screen and (min-width: 768px) {
      margin-left: 80px;
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;

      @media screen and (min-width: 768px) {
        height: 300px;
      }
    }

    .editProfile {
      color: #707070;
      background-color: #e8e8e8;
      padding: 8px;
      position: absolute;
      top: 15px;
      display: block;
      z-index: 100;
      text-decoration: none;
      right: 12px;

      @media screen and (min-width: 768px) {
        top: 276px;
        left: auto;
        transform: none;
        right: 12px;
      }

      svg {
        margin-right: 4px;
        margin-top: -2px;
      }
    }

    .closeLink {
      position: absolute;
      right: 12px;
      top: 180px;
      background-color: white;
      padding: 6px 12px;
      text-decoration: none;
      color: #707070;
      box-shadow: 0px 3px 6px #e8e8e8;

      @media screen and (min-width: 768px) {
        top: 20px;
      }

      svg {
        fill: #707070;
        width: 16px;
        height: 16px;
      }
    }
  }

  .container {
    @media screen and (min-width: 768px) {
      margin-left: 80px;
    }
  }

  form {
    width: 95%;

    .listItem {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 0.75rem;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.8;
      color: #707070;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .temMemDisable{
      display: none;
    }

    .addTeamMember {
      .signinLink {
        text-transform: uppercase;
        font-weight: 500;
        padding-top: 25px;
      }

      .signinLink.disable{
        color: #707070;
        pointer-events: none;
        cursor: default;
      }
    }

    .range-wrap {
      position: relative;
      margin: 0 10px;

      .form-range {
        margin-top: 3px;
      }

      input[type='range'] {
        height: 5px;
      }

      input[type=range]::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        height: 24px;
        width: 22px;
        background-image: url("../../../assets/svg/Bar01.svg");
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        -webkit-appearance: none;
        border: none;
        border-radius: 0;
        margin-top: -9px;
      }

      input[type=range]::-moz-range-thumb {
        z-index: 2;
        position: relative;
        height: 24px;
        width: 22px;
        background-image: url("../../../assets/svg/Bar01.svg");
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        border: none;
        border-radius: 0;
        margin-top: -9px;
      }

      input[type=range]::-ms-thumb {
        z-index: 2;
        position: relative;
        height: 24px;
        width: 22px;
        background-image: url("../../../assets/svg/Bar01.svg");
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        border: none;
        border-radius: 0;
        margin-top: -9px;
      }

      .bubble {
        background: transparent;
        color: #00A1ED;
        padding: 26px 12px;
        font-size: 18px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      ::-webkit-slider-runnable-track {
        background-color: $secondary;
        height: 3px;
      }

      input[type=range]:focus::-webkit-slider-runnable-track {
        background: #ccc;
      }

      ::-moz-range-progress {
        background-color: $secondary;
      }

      ::-ms-fill-lower {
        background-color: $secondary;
      }
    }
  }
}

</style>
