<template>
  <div class="mb-3">
    <label class="form-check-label">{{ label }}</label>
    <input type="text" class="form-control" :value="input" :required="required" @input="handleInput">
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: false,
      },
      required: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        input: this.value,
      }
    },
    methods: {
      handleInput (e) {
        this.input = e.target.value;
        this.$emit('update:value', e.target.value);
      }
    }

  }
</script>

<style scoped>

</style>
